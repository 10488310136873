import {
	getFormData,
	getFormsServiceApiDomain,
	mapDynamicServiceCode,
	mapServiceSpecificAttributes
} from '../../helpers';
/**
 * Submit trade-specific answers to HS Forms Service to get Consent Vendor Listings
 * @param {Object} serviceSpecificAttributes - trade specific answers
 */
export function getConsentListing(serviceSpecificAttributes) {
	const apiDomain = getFormsServiceApiDomain(),
		apiUrl = apiDomain + 'forms/consent-searches',
		providersWrapper = document.querySelector('.tcpa-providers'),
		targetDiv = document.querySelector('[data-providers-consent]');

	const quadLinkData = window.modForm.opts.quadLinkData.quadlink ? { 'quadlink': window.modForm.opts.quadLinkData.quadlink } : window.modForm.opts.quadLinkParams,
		service = mapDynamicServiceCode(getFormData());

	if (!serviceSpecificAttributes) {
		mapServiceSpecificAttributes(service);
	} else {
		window.modForm.opts.serviceSpecificAttributes = serviceSpecificAttributes;
	}


	const consentSearchData = {
		'zip': window.modForm.opts.getVars.zip ? window.modForm.opts.getVars.zip : window.modForm.opts.zipCode,
		'service': service,
		'site': window.modForm.opts.quadLinkData.WebSiteName,
		...quadLinkData,
		'serviceSpecificAttributes': {
			...window.modForm.opts.serviceSpecificAttributes
		}
	};

	let response = {};

	const request = new XMLHttpRequest();
	request.open('POST', apiUrl);
	request.setRequestHeader('Content-Type', 'application/json');
	request.send(JSON.stringify(consentSearchData));
	request.addEventListener('load', function() {
		if (this.response) {
			response = { ...response, ...JSON.parse(this.response) };
			if (this.readyState === 4 && this.status === 200) {
				if (response.data.length === 1 && response.data[0].vendorDisplayName === 'Modernize') {
					// If Modernize is the only response – remove the "Providers" section
					if (providersWrapper) {
						providersWrapper.style.display = 'none';
					}
				} else {
					if (providersWrapper && providersWrapper.style.display === 'none') {
						providersWrapper.style.display = 'block';
					}
				}

				if (JSON.stringify(response) !== window.modForm.opts.consentCaptureDetails) {
					if (targetDiv.hasChildNodes()) {
						while (targetDiv.firstChild) {
							targetDiv.removeChild(targetDiv.firstChild);
						}
					}
					window.modUtils.renderConsentCheckboxes(response.data, targetDiv);
					window.modForm.opts.consentCaptureDetails = JSON.stringify(response);
				}
			}
		}
	});

	request.addEventListener('error', function() {
		console.error('Something went wrong!');
	});
}
